import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'option'];

  search(event) {
    this.#populate(event.target.value);
  }

  pick(event) {
    this.#setInputValue(event.params.value);
  }

  clearOption() {
    this.#setInputValue('');
  }

  #setInputValue(value) {
    this.inputTarget.value = value;
    this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }));
  }

  #populate(query) {
    this.optionTargets.forEach((opt) => opt.classList.add('hidden'));
    const selectedOpts = this.#findOptions(query);
    selectedOpts.forEach((opt) => opt.classList.remove('hidden'));
  }

  #findOptions(query) {
    let selectedOptions = this.optionTargets;

    if (query) {
      const lowerQuery = query.toLowerCase();
      selectedOptions = this.optionTargets.filter((opt) =>
        opt.getAttribute('data-select-with-searchbar-text-param').toLowerCase().includes(lowerQuery)
      );
    }

    return selectedOptions;
  }
}
