import { Controller } from 'stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = ['form', 'fontSelectHeader', 'textArea'];

  static values = {
    previousData: Object,
    textsPath: String,
    defaultText: String,
  };

  connect() {
    this.previousDataValue = Object.fromEntries(new FormData(this.formTarget));

    // Highlight text & focus for user's convenience
    this.#highlightTextValue();
  }

  onChange() {
    const data = Object.fromEntries(new FormData(this.formTarget));

    // Send only values that have changed
    for (const [key, value] of Object.entries(data)) {
      if (this.previousDataValue[key] !== value) {
        this.dispatch(`change-${key}`, { detail: { [key]: value } });
      }
    }

    // font weight, style and decorations' unchecking can't be listened to as they're radio buttons. we're sending them always
    const radioElements = {
      font_weight: data['font_weight'] || '400',
      font_style: data['font_style'] || 'normal',
      text_decoration: data['text_decoration'] || 'none',
    };

    for (const [key, value] of Object.entries(radioElements)) {
      this.dispatch(`change-${key}`, { detail: { [key]: value } });
    }

    this.previousDataValue = data;
  }

  updateFontSelect(event) {
    this.fontSelectHeaderTarget.innerText = event.target.value;
  }

  goToTextMenu() {
    get(this.textsPathValue, {
      responseKind: 'turbo-stream',
    });
  }

  #highlightTextValue() {
    if (this.hasTextAreaTarget) {
      this.textAreaTarget.focus();
      const textValue = this.textAreaTarget.value;
      if (textValue === this.defaultTextValue) {
        this.textAreaTarget.select();
      } else {
        const length = textValue.length;
        this.textAreaTarget.setSelectionRange(length, length);
        this.textAreaTarget.scrollTop = this.textAreaTarget.scrollHeight;
      }
    }
  }
}
