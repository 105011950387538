import {
  TiptapBase,
  Heading,
  BulletList,
  OrderedList,
  Bold,
  Italic,
  Strike,
  History,
  FontFamily,
  Link,
  Underline,
  TextAlign,
  ListItem,
  TextStyle,
  AutoHideToolbar,
} from '@/js/util/tiptap/extensions';

import {
  FontFamilyHandlers,
  HeadingLevelHandlers,
  HorizontalRuleHandlers,
  LinksHandlers,
  ListsHandlers,
  TextAlignmentHandlers,
  TextFormattingHandlers,
  PresetHandlers,
} from '@/js/util/tiptap/handlers';

export default class DesignerNotesConfig {
  get extensions() {
    return [
      ...TiptapBase,
      ...Heading,
      ...BulletList,
      ...OrderedList,
      ...Bold,
      ...Italic,
      ...Strike,
      ...History,
      ...FontFamily,
      ...Link,
      ...Underline,
      ...TextAlign,
      ...ListItem,
      ...TextStyle,
      ...AutoHideToolbar,
    ];
  }

  get handlers() {
    return {
      ...FontFamilyHandlers,
      ...HeadingLevelHandlers,
      ...HorizontalRuleHandlers,
      ...LinksHandlers,
      ...ListsHandlers,
      ...TextAlignmentHandlers,
      ...TextFormattingHandlers,
      ...PresetHandlers,
    };
  }
}
