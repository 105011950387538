import { Controller } from 'stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static values = {
    boardId: Number,
  };

  async showTextControls(event) {
    await this.renderSideDrawer('text', 'text_controls', event.detail);
    this.controller = null;
  }

  async renderSideDrawer(openTabName, openTabUrl, params) {
    this.controller = new AbortController();
    const signal = this.controller.signal;

    const url = new URL(`/boards/${this.boardIdValue}/editor_drawer`, window.location.origin);

    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.set(`open_tab_params[${key}]`, value);
    });

    url.searchParams.append('open_tab_name', openTabName);
    url.searchParams.append('open_tab_url', openTabUrl);

    return get(url, { responseKind: 'turbo-stream', signal });
  }

  cancelRequests() {
    if (this.controller) {
      this.controller.abort();
    }
  }
}
