import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];
  connect() {
    const self = this;

    $(this.element).minicolors({
      change(value) {
        self.inputTarget.value = value;
        self.inputTarget.dispatchEvent(new Event('change'));
      },
    });
  }
}
