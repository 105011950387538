/* global DfApi */
import { buildHtmlElement } from '@/js/util/build_html_element';
import { appFor } from '@/js/util/tiptap/extensions/_get_menu_control';
const api = () => new DfApi();

const notFoundError = (_response) => {
  alert('Preset not found');
};

const emptyNameError = () => {
  alert('You need to provide a non-empty name');
};

const kindOfPreset = (editor) => {
  const app = appFor(editor);
  const variant = app.dataset.tiptapEditorVariantValue;

  return { standard: 'contract', invoice_notes: 'invoice' }[variant];
};

const creationSuccess = async (response) => {
  const { id, title } = await response.json();
  const substitutions = { id, title };
  const presetGroup = buildHtmlElement('preset-menu-control', substitutions);

  document.querySelector('.presets__collection').appendChild(presetGroup);
};

const creationError = (response, name) => {
  alert(`An error occurred while creating preset ${name}`);
};

const createPreset = async (editor, name) => {
  const details = editor.getHTML();
  const presetKind = kindOfPreset(editor);
  const response = await api().createPreset(name, presetKind, details);

  response.ok ? creationSuccess(response) : creationError(response, name);
};

const writePresetToEditor = async (response, editor) => {
  const { tiptap_details: details } = await response.json();
  editor.commands.setContent(details, true);
};

const loadPreset = async (editor, button) => {
  const presetId = button.value;
  const response = await api().getPreset(presetId);

  response.ok ? writePresetToEditor(response, editor) : notFoundError(response);
};

const savePreset = (editor, button) => {
  const name = prompt('Enter a preset name');
  const emptyName = name.trim().length === 0;

  emptyName ? emptyNameError() : createPreset(editor, name);
};

const deletePreset = async (editor, button) => {
  const remove = confirm(`Do you want to delete ${button.dataset.name}?`);

  if (remove) {
    const presetId = button.value;
    const response = await api().deletePreset(presetId);

    response.ok ? button.parentElement.remove() : notFoundError(response);
  }
};

const presetHandlers = {
  'preset:load': loadPreset,
  'preset:save': savePreset,
  'preset:delete': deletePreset,
};

export default presetHandlers;
