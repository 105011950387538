import Util from '~/js/editor/util';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['uiLayer'];

  static classes = ['ghostThumb'];

  static values = {
    thumbId: String,
    zoomedThumbUrl: String,
    kind: String,
    imageIds: String,
    imageId: String,
    font: String,
    text: String,
  };

  connect() {
    const self = this;

    window
      .interact(self.element)
      .draggable({
        manualStart: true,
        onmove(event) {
          self.#dragGhostThumb(event);
        },
        onend(event) {
          self.#endGhostDrag(event);
        },
      })
      .on('move', function (event) {
        self.#startGhostDrag(event);
      });
  }

  onThumbPictureChanged(event) {
    this.thumbIdValue = event.detail.id;
    this.zoomedThumbUrlValue = event.detail.zoomedUrl;
    this.kindValue = event.detail.kind;
    this.imageIdsValue = event.detail.imageIds;
    this.imageIdValue = event.detail.imageId;
  }

  #startGhostDrag(event) {
    const { interaction } = event;

    if (interaction.pointerIsDown && !interaction.interacting()) {
      const original = event.currentTarget;
      const clone = original.cloneNode(true);

      clone
        .querySelector("[data-moodboard--drawers--products--thumb-target='uiLayer']")
        ?.classList.add('hidden');

      clone.classList.value = this.ghostThumbClasses.join(' ');

      const originalRect = original.getBoundingClientRect();
      const canvasRect = window.ed.boardContainer.getBoundingClientRect();
      clone.e = {
        x: originalRect.left - canvasRect.left,
        y: originalRect.top - canvasRect.top,
      };

      window.ed.boardContainer.prepend(clone);

      Object.assign(event.interactable, { distX: 0, distY: 0 });

      interaction.start({ name: 'drag' }, event.interactable, clone);
    }
  }

  #dragGhostThumb(event) {
    const { target, interactable, dx, dy } = event;

    if (Util.isDefined(interactable.distX)) {
      // Update distances
      interactable.distX += dx;
      interactable.distY += dy;

      const absDistX = Math.abs(interactable.distX);
      const absDistY = Math.abs(interactable.distY);
      const isDragHorizontallyHigher = absDistX <= absDistY + 5;

      if (!Util.isTouchScreen() || isDragHorizontallyHigher) {
        interactable.proceed = true;
        target.style.display = 'block';
      }

      if (absDistX > 10 || absDistY > 10 || isDragHorizontallyHigher) {
        delete interactable.distX;
        delete interactable.distY;
      }
    } else {
      interactable.proceed = true;
      target.style.display = 'block';
    }

    if (interactable.proceed) {
      target.e.x += dx;
      target.e.y += dy;

      target.style.left = `${target.e.x}px`;
      target.style.top = `${target.e.y}px`;
    }
  }

  #endGhostDrag(event) {
    const { target } = event;
    const { proceed } = event.interactable;

    target.remove();

    if (proceed) {
      const e = {
        id: this.thumbIdValue,
        kind: this.kindValue,
        x: target.e.x,
        y: target.e.y,
        centerItem: true,
        newlyAdded: true,
        ...(this.imageIdsValue !== '' && { imageIds: this.imageIdsValue }),
        ...(this.imageIdValue !== '' && { imageId: this.imageIdValue }),
        ...(this.kindValue === 'text' && { font: this.fontValue, text: this.textValue }),
      };
      window.ed._shared.addItem({ e, selectItem: true, updateSingleItemZoomRatio: true });

      delete event.interactable.proceed;
    }
  }
}
