import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];

  inputTargetConnected(target) {
    target.addEventListener('input', this.sync.bind(this));
  }

  sync(event) {
    this.inputTargets.forEach((input) => {
      if (event.target !== input) {
        input.value = event.target.value;
      }
    });

    event.target.dispatchEvent(new Event('synced'));
  }

  onInput(event) {
    const inputEl = event.target;
    const value = Number(inputEl.value);
    const min = Number(inputEl.min);
    const max = Number(inputEl.max);

    if (!Number.isNaN(min) && value < min) {
      inputEl.value = min;
    }

    if (!Number.isNaN(max) && value > max) {
      inputEl.value = max;
    }
  }
}
